@font-face {
  font-family: 'Kalam';
  src: url(./assets/fonts/Kalam/Kalam-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Kalam';
  src: url(./assets/fonts/Kalam/Kalam-Bold.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Kalam';
  src: url(./assets/fonts/Kalam/Kalam-Light.ttf) format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Sniglet';
  src: url(./assets/fonts/Sniglet/Sniglet-Regular.ttf) format('truetype');
  font-display: swap;
}

* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-image: url(./assets/images/pusheen_background_clouds.jpg);
}

.main {
  text-align: center;
}

.main h1 {
  font-size: 3rem;
  font-family: 'Kalam';
  color: darkslateblue;
  margin: 1rem 0rem 1rem 0rem;
}

.sosanimal-form {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-family: 'Sniglet';
  margin: 1rem 0rem;
}

.sosanimal-form label {
  margin: 0rem 0rem 0rem 1rem;
}

.sosanimal-form .animal-button {
  margin: 1.2rem 0rem 0rem 1.2rem;
  font-size: 1.3rem;
  border-radius: 1.3rem;
}

.sosanimal-form .animal-form {
  font-size: 1.2rem;
  border-radius: 0.5rem;
}

@media (max-width: 974px) {
  .sosanimal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'Sniglet';
    margin: 1rem 0rem;
  }

  .sosanimal-form label {
    display: flex;
    margin: 0.5rem 0rem 0rem 0rem;
  }

  .sosanimal-form input {
    display: flex;
    margin: 0rem 0rem 0rem 0rem;
  }

  .sosanimal-form select {
    display: flex;
    margin: 0rem 0rem 0rem 0rem;
  }

  .sosanimal-form .animal-button {
    margin: 1.5rem 0rem 0rem 0rem;
    font-size: 1.3rem;
    border-radius: 1.3rem;
  }
}

.address {
  font-size: 1.8rem;
  font-family: 'Sniglet';
  margin: 1rem 0rem;
}

.sosanimal-message p {
  font-size: 1.8rem;
  font-family: 'Sniglet';
  margin: 1rem 0rem;
}

.tweet-button p {
  font-size: 1.4rem;
  font-family: 'Sniglet';
  margin: 0rem;
}

.rendered-map {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 1rem 0rem;
}

.load-error {
  text-align: center;
  font-size: 5rem;
  font-family: 'Sniglet';
  margin: 25rem;
}

@media (max-width: 1088px) {
  .load-error {
    display: flex;
    justify-content: center;
    font-size: 3.1rem;
    font-family: 'Sniglet';
    margin: 10rem;
  }
}

.github-redirector img {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2rem;
  margin: 0.5rem;
}
